import React, { createContext, ReactNode, useState } from "react";

interface AppContextProps {
	modalState: string;
	setModalState: React.Dispatch<React.SetStateAction<string>>;
	providerID: number;
	setProviderID: React.Dispatch<React.SetStateAction<number>>;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
	children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const [modalState, setModalState] = useState<string>("");
	const [providerID, setProviderID] = useState<number>(0);

	const contextValue: AppContextProps = {
		modalState,
		setModalState,
		providerID,
		setProviderID
	};

	return (
		<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
	);
};

export default AppProvider;
